<template>
  <div id="detergentMakeIssue">
    <el-dialog
      :title="detergentMakeIssueFormTitle"
      width="680px"
      :visible.sync="detergentMakeIssueDialogVisible"
      :close-on-click-modal="false"
      @close="detergentMakeIssueDialogClose"
    >
      <el-form
        ref="detergentMakeIssueFormRef"
        :model="detergentMakeIssueForm"
        :rules="detergentMakeIssueFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="配制日期" prop="makeDate">
              <el-date-picker
                v-model="detergentMakeIssueForm.makeDate"
                placeholder="请选择配制日期"
                value-format="yyyy-MM-dd"
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="洗涤剂种类" prop="type">
              <el-radio-group
                v-model="detergentMakeIssueForm.type"
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              >
                <el-radio :label="1">
                  1％洗衣粉溶液
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="洗涤剂取用量" prop="dosage">
              <el-input
                v-model="detergentMakeIssueForm.dosage"
                placeholder="请输入洗涤剂取用量"
                clearable
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制后容量" prop="capacity">
              <el-input
                v-model="detergentMakeIssueForm.capacity"
                placeholder="请输入配制后容量"
                clearable
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="validPeriod">
              <el-input
                v-model="detergentMakeIssueForm.validPeriod"
                placeholder="请输入有效期"
                clearable
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="配制人" prop="preparer">
              <el-input
                v-model="detergentMakeIssueForm.preparer"
                placeholder="请输入配制人"
                clearable
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="日期" prop="preparationDate">
              <el-date-picker
                v-model="detergentMakeIssueForm.preparationDate"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
                :disabled="detergentMakeIssueFormTitle !== '新增洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '修改洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              />
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="领用结果" prop="receivedResult">
              <el-radio-group
                v-model="detergentMakeIssueForm.receivedResult"
                :disabled="detergentMakeIssueFormTitle !== '领用洗涤剂配制和发放记录'
                  && detergentMakeIssueFormTitle !== '洗涤剂配制和发放记录详情'"
              >
                <el-radio :label="1">
                  已领用
                </el-radio>
                <el-radio :label="2">
                  未领用
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="detergentMakeIssueDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="detergentMakeIssueFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="配制日期">
        <el-date-picker v-model="searchForm.makeDate" placeholder="请选择配制日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['DETERGENT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="detergentMakeIssuePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="配制日期">
        <template slot-scope="scope">
          <span v-if="scope.row.makeDate">{{ scope.row.makeDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="洗涤剂种类">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">1％洗衣粉溶液</span>
        </template>
      </el-table-column>
      <el-table-column prop="dosage" label="洗涤剂取用量" />
      <el-table-column prop="capacity" label="配制后容量" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="preparer" label="配制人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.preparationDate">{{ scope.row.preparationDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="recipient" label="领用人" />
      <!-- <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.receivedDate">{{ scope.row.receivedDate.substring(0, 10) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="领用结果">
        <template slot-scope="scope">
          <span v-if="scope.row.receivedResult === 1">已领用</span>
          <span v-if="scope.row.receivedResult === 2">未领用</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DETERGENT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DETERGENT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['DETERGENT_RECEIVE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReceive(scope.$index, scope.row)"
          >
            领用
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="detergentMakeIssuePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDetergentMakeIssue,
  deleteDetergentMakeIssue,
  updateDetergentMakeIssue,
  selectDetergentMakeIssueInfo,
  selectDetergentMakeIssueList,
  receiveDetergentMakeIssue
} from '@/api/produce/detergentMakeIssue'

export default {
  data () {
    return {
      detergentMakeIssueDialogVisible: false,
      detergentMakeIssueFormTitle: '',
      detergentMakeIssueForm: {
        id: '',
        makeDate: '',
        type: 1,
        dosage: '',
        capacity: '',
        validPeriod: '',
        preparer: '',
        preparationDate: '',
        receivedResult: '',
        status: '',
        taskId: ''
      },
      detergentMakeIssueFormRules: {
        makeDate: [{ required: true, message: '配制日期不能为空', trigger: ['blur', 'change']}]
      },
      detergentMakeIssuePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        makeDate: ''
      }
    }
  },
  created () {
    selectDetergentMakeIssueList(this.searchForm).then(res => {
      this.detergentMakeIssuePage = res
    })
  },
  methods: {
    detergentMakeIssueDialogClose () {
      this.$refs.detergentMakeIssueFormRef.resetFields()
    },
    detergentMakeIssueFormSubmit () {
      if (this.detergentMakeIssueFormTitle === '洗涤剂配制和发放记录详情') {
        this.detergentMakeIssueDialogVisible = false
        return
      }
      this.$refs.detergentMakeIssueFormRef.validate(async valid => {
        if (valid) {
          if (this.detergentMakeIssueFormTitle === '新增洗涤剂配制和发放记录') {
            this.detergentMakeIssueForm.id = ''
            this.detergentMakeIssueForm.status = 1
            await addDetergentMakeIssue(this.detergentMakeIssueForm)
          } else if (this.detergentMakeIssueFormTitle === '修改洗涤剂配制和发放记录') {
            await updateDetergentMakeIssue(this.detergentMakeIssueForm)
          } else if (this.detergentMakeIssueFormTitle === '领用洗涤剂配制和发放记录') {
            this.detergentMakeIssueForm.status = 2
            await receiveDetergentMakeIssue(this.detergentMakeIssueForm)
          }
          this.detergentMakeIssuePage = await selectDetergentMakeIssueList(this.searchForm)
          this.detergentMakeIssueDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.detergentMakeIssueFormTitle = '新增洗涤剂配制和发放记录'
      this.detergentMakeIssueDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDetergentMakeIssue(row.id)
        if (this.detergentMakeIssuePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.detergentMakeIssuePage = await selectDetergentMakeIssueList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.detergentMakeIssueFormTitle = '修改洗涤剂配制和发放记录'
      this.detergentMakeIssueDialogVisible = true
      this.selectDetergentMakeIssueInfoById(row.id)
    },
    handleReceive (index, row) {
      this.detergentMakeIssueFormTitle = '领用洗涤剂配制和发放记录'
      this.detergentMakeIssueDialogVisible = true
      this.selectDetergentMakeIssueInfoById(row.id)
    },
    handleInfo (index, row) {
      this.detergentMakeIssueFormTitle = '洗涤剂配制和发放记录详情'
      this.detergentMakeIssueDialogVisible = true
      this.selectDetergentMakeIssueInfoById(row.id)
    },
    selectDetergentMakeIssueInfoById (id) {
      selectDetergentMakeIssueInfo(id).then(res => {
        this.detergentMakeIssueForm.id = res.id
        this.detergentMakeIssueForm.makeDate = res.makeDate
        this.detergentMakeIssueForm.type = res.type
        this.detergentMakeIssueForm.dosage = res.dosage
        this.detergentMakeIssueForm.capacity = res.capacity
        this.detergentMakeIssueForm.validPeriod = res.validPeriod
        this.detergentMakeIssueForm.preparer = res.preparer
        this.detergentMakeIssueForm.preparationDate = res.preparationDate
        this.detergentMakeIssueForm.receivedResult = res.receivedResult
        this.detergentMakeIssueForm.status = res.status
        this.detergentMakeIssueForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDetergentMakeIssueList(this.searchForm).then(res => {
        this.detergentMakeIssuePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDetergentMakeIssueList(this.searchForm).then(res => {
        this.detergentMakeIssuePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDetergentMakeIssueList(this.searchForm).then(res => {
        this.detergentMakeIssuePage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['DETERGENT_RECEIVE']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
