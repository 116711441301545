import axios from '@/common/axios'
import qs from 'qs'

export function addDetergentMakeIssue (data) {
  return axios({
    method: 'post',
    url: '/production/detergentMakeIssue/add',
    data: qs.stringify(data)
  })
}

export function deleteDetergentMakeIssue (id) {
  return axios({
    method: 'delete',
    url: '/production/detergentMakeIssue/delete/' + id
  })
}

export function updateDetergentMakeIssue (data) {
  return axios({
    method: 'put',
    url: '/production/detergentMakeIssue/update',
    data: qs.stringify(data)
  })
}

export function selectDetergentMakeIssueInfo (id) {
  return axios({
    method: 'get',
    url: '/production/detergentMakeIssue/info/' + id
  })
}

export function selectDetergentMakeIssueList (query) {
  return axios({
    method: 'get',
    url: '/production/detergentMakeIssue/list',
    params: query
  })
}

export function receiveDetergentMakeIssue (data) {
  return axios({
    method: 'put',
    url: '/production/detergentMakeIssue/updateReceive',
    data: qs.stringify(data)
  })
}
